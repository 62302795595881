import { baseRequest } from './index';

/**
 * 我提交的周报
 * @params data Object
 * @returns MenusInfo
*/
export const myWeekReport = (data, aid) => baseRequest.post('/crmapp/weekReport/myWeekReport', data, {
  headers: {
    'aid': aid
  }
});

/**
 * 提交给我的周报
 * @params data Object
 * @returns MenusInfo
*/
export const weekReportSubmitToMe = (data, aid) => baseRequest.post('/crmapp/weekReport/submitToMe', data, {
  headers: {
    'aid': aid
  }
});

/**
 * 周报详情页面
 * @params reportId int
 * @returns MenusInfo
*/
export const weekReportDeatail = (reportId) => baseRequest.post('/crmapp/weekReport/detail', {reportId});

/**
 * 周报阅读情况列表
 * @params reportId int
 * @returns MenusInfo
*/
export const weekReportCommentList = (data) => baseRequest.post('/crmapp/weekReport/commentList', data);

/**
 * 添加评论
 * @params data Object
 * @returns MenusInfo
*/
export const addComment = (data) => baseRequest.post('/crmapp/weekReport/addComment', data);

/**
 * 获取提交给我的未读总数
 * @params staffId String
 * @returns MenusInfo
*/
export const unReadNumber = (staffId) => baseRequest.post('/crmapp/weekReport/unReadNumber', {staffId});

