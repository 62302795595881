import { render, staticRenderFns } from "./add-comments.vue?vue&type=template&id=71e2855e&scoped=true"
import script from "./add-comments.vue?vue&type=script&lang=js"
export * from "./add-comments.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/components/btn-bottom.scss?vue&type=style&index=0&id=71e2855e&prod&lang=scss&scoped=true&external"
import style1 from "@/assets/css/components/addstyle.scss?vue&type=style&index=1&id=71e2855e&prod&lang=scss&scoped=true&external"
import style2 from "./add-comments.vue?vue&type=style&index=2&id=71e2855e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e2855e",
  null
  
)

export default component.exports