<template>
  <div class="section">
    <!-- <cube-textarea v-model="visitPurpose" :maxlength="maxlength" placeholder='请输入内容' autofocus class="comments-textarea"></cube-textarea> -->
    <cube-form
      :model="model"
      class="form-wrapper"
      @submit="submitHandler"
    >
      <cube-form-item
        class="comments-textarea"
        :field="fields[0]"
      >
        <cube-textarea
          v-model="model.comment"
          placeholder="请输入内容"
          :maxlength="maxlength"
          :auto-expand="true"
          :autofocus="true"
          :disabled="false"
          @input.native="keydown"
        />
      </cube-form-item>
      <cube-form-item :field="fields[1]" />
      <div class="bottom-postion">
        <cube-button
          type="submit"
          :disabled="!changeAlive"
          class="btn"
        >
          保存
        </cube-button>
      </div>
    </cube-form>
  </div>
</template>

<script>
import config from '../../lib/config';
import { addComment } from '_api/workreport';
export default {
  name: 'AddComments',
  beforeRouteLeave(to, from, next) {
    if (this.changeAlive && from.name === 'AddComments' && (this.model.comment !== '' || this.model.fileId.length) && this.banPop) {
      this.toDialog(next);
    } else {
      next();
    }
  },
  data() {
    return {
      maxlength: 500,
      model: {
        comment: '',
        fileId: []
      },
      fields: [
        {
          type: 'textarea',
          modelKey: 'comment',
          props: { placeholder: '请输入内容', maxlength: 500, autoExpand: true, autofocus: true, disabled: false },
          rules: { required: true }
        },
        {
          type: 'upload',
          modelKey: 'fileId',
          events: {
            'file-click': (...args) => {
              let imgs = args[0].response ? [args[0].response.data.record ? args[0].response.data.record.fileUrl : ''] : [args[0].fileUrl];
              this.$createImagePreview({
                imgs: imgs
              }).show();
            }
          },
          props: {
            max: 1,
            action: {
              target: `${config.BASE_URL}/client/tencent/getFileAndFileIdFeign`,
              headers: { token: this.$store.state.token },
              data: {
                type: 99,
                time: 0
              }
            }
          },
          messages: {
            uploaded: '上传失败'
          }
        }
      ],
      changeAlive: true,
      banPop: true // @人设置缓存开关
    };
  },
  activated() {
    this.aitEvaluat();
  },
  methods: {
    toDialog(next) {
      this.$createDialog(
        {
          type: 'confirm',
          icon: 'cubeic-alert',
          title: '保留本次编辑',
          // content: `是否将线索线索名称转移给${orgsales.transferSales.name}?`,
          confirmBtn: {
            text: '保留',
            active: true,
            disabled: false,
            href: 'javascript:;'
          },
          cancelBtn: {
            text: '不保留',
            active: false,
            disabled: false,
            href: 'javascript:;'
          },
          onConfirm: () => {
            next();
          },
          onCancel: () => {
            // 不保留清除页面缓存
            this.SET_KEEPALIVE([]);
            next();
          }
        }).show();
    },
    submitHandler(e) {
      e.returnValue = false;
      let params = JSON.parse(JSON.stringify(this.model));
      params.fileId = params.fileId.map(item => item.response ? item.response.data.record.fileId : item.fileId).join();
      addComment({...params, reportId: this.$route.params.reportId}).then(res => {
        if (res.flag) {
          this.$showToast('保存成功');
          this.$router.go(-1);
          this.SET_KEEPALIVE([]);
          this.changeAlive = false;
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    keydown(e) {
      this.banPop = true;
      if (e.data === '@') {
        this.banPop = false;
        this.$router.push(
          {
            name: 'tree',
            params: {
              routerName: 'AddComments',
              typeName: 'aitName'
            }
          }
        );
      }
    },
    // @赋值
    aitEvaluat() {
      this.banPop = true;
      this.model.comment += this.airName || '';
      this.AIR_NAME('');
    }
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped src="@/assets/css/components/addstyle.scss"></style>
<style lang="scss">
.comments-textarea{
  .cube-validator-content{
    height: 220px;
    .cube-textarea-wrapper{
      height: 172px;
    }
  }
}
.border-bottom-1px::after{
  border: 0;
}
</style>
